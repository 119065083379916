*,
*::before,
*::after {
	box-sizing: border-box;
}

:root {
	@media (prefers-reduced-motion: no-preference) {
		scroll-behavior: smooth;
	}
}

body {
	margin: 0; // 1
	font-family: var(--font-primary-family);
	color: var(--sys-on-background);
	-webkit-text-size-adjust: 100%; // 3
	-webkit-tap-highlight-color: var(--sys-on-surface-variant);
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0;
	text-wrap: balance;
}

p {
	margin-top: 0;
	text-wrap: pretty;
}
