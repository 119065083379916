
		:host {
			display: block;
		}
		#logo_scb {
			width: 100%;
			height: 100%;
			--_logoorange: var(--logoorange-color, #d57e00);
			--_logored: var(--logored-color, #6a1a31);
			--_logobrown: var(--logobrown-color, #443736);
			--_stroke-small: 0px;
			--_stroke-big: 0px;
			.bistrot-text-bold {
				display: none;
			}
			.bistrot-text-std {
				display: block;
			}
		}

		.bold #logo_scb {
			--_stroke-small: 8px;
			--_stroke-big: 14px;

			.bistrot-text-bold {
				display: block;
			}
			.bistrot-text-std {
				display: none;
			}
		}
		.white #logo_scb {
			--_logoorange: white;
			--_logored: white;
			--_logobrown: white;
		}
		.black #logo_scb {
			--_logoorange: black;
			--_logored: black;
			--_logobrown: black;
		}
	