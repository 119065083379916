:root {
	--logoorange-color: #d57e00;
	--logored-color: #6a1a31;
	--logobrown-color: #443736;

	--font-primary-family: Montserrat, sans-serif;
	--font-display-family: Roboto Slab, sans-serif;
	--font-tertiary-family: Barlow, sans-serif;
	--toolbar-height: 64px;

	--sys-primary: #392121;
	--sys-surface-tint: rgb(117 87 87);
	--sys-on-primary: rgb(255 255 255);
	--sys-primary-container: rgb(93 66 66);
	--sys-on-primary-container: rgb(255 219 218);
	--sys-secondary: #63152c;
	--sys-on-secondary: rgb(255 255 255);
	--sys-secondary-container: rgb(144 56 78);
	--sys-on-secondary-container: rgb(255 249 249);
	--sys-tertiary: #8b5000;
	--sys-on-tertiary: rgb(255 255 255);
	--sys-tertiary-container: rgb(255 159 44);
	--sys-on-tertiary-container: rgb(64 34 0);
	--sys-error: #a30002;
	--sys-on-error: rgb(255 255 255);
	--sys-error-container: rgb(231 8 8);
	--sys-on-error-container: rgb(255 255 255);
	--sys-background: rgb(255 248 247);
	--sys-on-background: rgb(30 27 27);
	--sys-surface: #fdf8f7;
	--sys-on-surface: rgb(28 27 27);
	--sys-surface-variant: rgb(240 223 222);
	--sys-on-surface-variant: rgb(79 68 68);
	--sys-outline: rgb(129 116 115);
	--sys-outline-variant: rgb(211 195 194);
	--sys-shadow: rgb(0 0 0);
	--sys-scrim: rgb(0 0 0);
	--sys-inverse-surface: rgb(49 48 47);
	--sys-inverse-on-surface: rgb(244 240 238);
	--sys-inverse-primary: rgb(228 189 189);
	--sys-primary-fixed: rgb(255 218 217);
	--sys-on-primary-fixed: rgb(43 21 22);
	--sys-primary-fixed-dim: rgb(228 189 189);
	--sys-on-primary-fixed-variant: rgb(91 64 64);
	--sys-secondary-fixed: rgb(255 217 222);
	--sys-on-secondary-fixed: rgb(63 0 21);
	--sys-secondary-fixed-dim: rgb(255 178 191);
	--sys-on-secondary-fixed-variant: rgb(125 41 63);
	--sys-tertiary-fixed: rgb(255 220 190);
	--sys-on-tertiary-fixed: rgb(44 22 0);
	--sys-tertiary-fixed-dim: rgb(255 184 112);
	--sys-on-tertiary-fixed-variant: rgb(105 60 0);
	--sys-surface-dim: rgb(221 217 216);
	--sys-surface-bright: rgb(253 248 247);
	--sys-surface-container-lowest: rgb(255 255 255);
	--sys-surface-container-low: rgb(247 243 241);
	--sys-surface-container: rgb(241 237 235);
	--sys-surface-container-high: rgb(235 231 230);
	--sys-surface-container-highest: rgb(230 226 224);
	--success-color: rgb(9 110 0);
	--success-on-color: rgb(255 255 255);
	--success-color-container: rgb(53 227 31);
	--success-on-color-container: rgb(3 63 0);
	--partial-color: rgb(140 79 0);
	--partial-on-color: rgb(255 255 255);
	--partial-color-container: rgb(255 161 60);
	--partial-on-color-container: rgb(66 35 0);
	--info-color-color: rgb(0 78 99);
	--info-color-on-color: rgb(255 255 255);
	--info-color-color-container: rgb(33 116 142);
	--info-color-on-color-container: rgb(255 255 255);

	// TYPOGRAPHY
	--sys-body-large: 400 1rem / 1.5rem var(--font-primary-family);
	--sys-body-large-font: var(--font-primary-family);
	--sys-body-large-line-height: 1.5rem;
	--sys-body-large-size: 1rem;
	--sys-body-large-tracking: 0.031rem;
	--sys-body-large-weight: 400;
	--sys-body-medium: 400 0.875rem / 1.25rem var(--font-primary-family);
	--sys-body-medium-font: var(--font-primary-family);
	--sys-body-medium-line-height: 1.25rem;
	--sys-body-medium-size: 0.875rem;
	--sys-body-medium-tracking: 0.016rem;
	--sys-body-medium-weight: 400;
	--sys-body-small: 400 0.75rem / 1rem var(--font-primary-family);
	--sys-body-small-font: var(--font-primary-family);
	--sys-body-small-line-height: 1rem;
	--sys-body-small-size: 0.75rem;
	--sys-body-small-tracking: 0.025rem;
	--sys-body-small-weight: 400;
	--sys-display-large: 400 3.562rem / 4rem var(--font-display-family);
	--sys-display-large-font: var(--font-display-family);
	--sys-display-large-line-height: 4rem;
	--sys-display-large-size: 3.562rem;
	--sys-display-large-tracking: -0.016rem;
	--sys-display-large-weight: 400;
	--sys-display-medium: 400 2.812rem / 3.25rem var(--font-display-family);
	--sys-display-medium-font: var(--font-display-family);
	--sys-display-medium-line-height: 3.25rem;
	--sys-display-medium-size: 2.812rem;
	--sys-display-medium-tracking: 0rem;
	--sys-display-medium-weight: 400;
	--sys-display-small: 400 2.25rem / 2.75rem var(--font-display-family);
	--sys-display-small-font: var(--font-display-family);
	--sys-display-small-line-height: 2.75rem;
	--sys-display-small-size: 2.25rem;
	--sys-display-small-tracking: 0rem;
	--sys-display-small-weight: 400;
	--sys-headline-large: 400 2rem / 2.5rem var(--font-primary-family);
	--sys-headline-large-font: var(--font-primary-family);
	--sys-headline-large-line-height: 2.5rem;
	--sys-headline-large-size: 2rem;
	--sys-headline-large-tracking: 0rem;
	--sys-headline-large-weight: 400;
	--sys-headline-medium: 400 1.75rem / 2.25rem var(--font-primary-family);
	--sys-headline-medium-font: var(--font-primary-family);
	--sys-headline-medium-line-height: 2.25rem;
	--sys-headline-medium-size: 1.75rem;
	--sys-headline-medium-tracking: 0rem;
	--sys-headline-medium-weight: 400;
	--sys-headline-small: 400 1.5rem / 2rem var(--font-primary-family);
	--sys-headline-small-font: var(--font-primary-family);
	--sys-headline-small-line-height: 2rem;
	--sys-headline-small-size: 1.5rem;
	--sys-headline-small-tracking: 0rem;
	--sys-headline-small-weight: 400;
	--sys-label-large: 500 0.875rem / 1.25rem var(--font-tertiary-family);
	--sys-label-large-font: var(--font-tertiary-family);
	--sys-label-large-line-height: 1.25rem;
	--sys-label-large-size: 0.875rem;
	--sys-label-large-tracking: 0.006rem;
	--sys-label-large-weight: 500;
	--sys-label-large-weight-prominent: 700;
	--sys-label-medium: 500 0.75rem / 1rem var(--font-tertiary-family);
	--sys-label-medium-font: var(--font-tertiary-family);
	--sys-label-medium-line-height: 1rem;
	--sys-label-medium-size: 0.75rem;
	--sys-label-medium-tracking: 0.031rem;
	--sys-label-medium-weight: 500;
	--sys-label-medium-weight-prominent: 700;
	--sys-label-small: 500 0.688rem / 1rem var(--font-tertiary-family);
	--sys-label-small-font: var(--font-tertiary-family);
	--sys-label-small-line-height: 1rem;
	--sys-label-small-size: 0.688rem;
	--sys-label-small-tracking: 0.031rem;
	--sys-label-small-weight: 500;
	--sys-title-large: 400 1.375rem / 1.75rem var(--font-primary-family);
	--sys-title-large-font: var(--font-primary-family);
	--sys-title-large-line-height: 1.75rem;
	--sys-title-large-size: 1.375rem;
	--sys-title-large-tracking: 0rem;
	--sys-title-large-weight: 400;
	--sys-title-medium: 500 1rem / 1.5rem var(--font-primary-family);
	--sys-title-medium-font: var(--font-primary-family);
	--sys-title-medium-line-height: 1.5rem;
	--sys-title-medium-size: 1rem;
	--sys-title-medium-tracking: 0.009rem;
	--sys-title-medium-weight: 500;
	--sys-title-small: 500 0.875rem / 1.25rem var(--font-primary-family);
	--sys-title-small-font: var(--font-primary-family);
	--sys-title-small-line-height: 1.25rem;
	--sys-title-small-size: 0.875rem;
	--sys-title-small-tracking: 0.006rem;
	--sys-title-small-weight: 500;
}
.dark-theme {
	--sys-primary: rgb(228 189 189);
	--sys-surface-tint: rgb(228 189 189);
	--sys-on-primary: rgb(67 42 42);
	--sys-primary-container: rgb(67 42 42);
	--sys-on-primary-container: rgb(220 182 181);
	--sys-secondary: rgb(255 178 191);
	--sys-on-secondary: rgb(95 17 41);
	--sys-secondary-container: rgb(114 32 54);
	--sys-on-secondary-container: rgb(255 195 205);
	--sys-tertiary: rgb(255 193 133);
	--sys-on-tertiary: rgb(74 40 0);
	--sys-tertiary-container: rgb(238 141 0);
	--sys-on-tertiary-container: rgb(41 20 0);
	--sys-error: rgb(255 180 168);
	--sys-on-error: rgb(105 0 1);
	--sys-error-container: rgb(216 0 4);
	--sys-on-error-container: rgb(255 255 255);
	--sys-background: rgb(22 19 19);
	--sys-on-background: rgb(232 225 224);
	--sys-surface: rgb(20 19 19);
	--sys-on-surface: rgb(230 226 224);
	--sys-surface-variant: rgb(79 68 68);
	--sys-on-surface-variant: rgb(211 195 194);
	--sys-outline: rgb(156 141 141);
	--sys-outline-variant: rgb(79 68 68);
	--sys-shadow: rgb(0 0 0);
	--sys-scrim: rgb(0 0 0);
	--sys-inverse-surface: rgb(230 226 224);
	--sys-inverse-on-surface: rgb(49 48 47);
	--sys-inverse-primary: rgb(117 87 87);
	--sys-primary-fixed: rgb(255 218 217);
	--sys-on-primary-fixed: rgb(43 21 22);
	--sys-primary-fixed-dim: rgb(228 189 189);
	--sys-on-primary-fixed-variant: rgb(91 64 64);
	--sys-secondary-fixed: rgb(255 217 222);
	--sys-on-secondary-fixed: rgb(63 0 21);
	--sys-secondary-fixed-dim: rgb(255 178 191);
	--sys-on-secondary-fixed-variant: rgb(125 41 63);
	--sys-tertiary-fixed: rgb(255 220 190);
	--sys-on-tertiary-fixed: rgb(44 22 0);
	--sys-tertiary-fixed-dim: rgb(255 184 112);
	--sys-on-tertiary-fixed-variant: rgb(105 60 0);
	--sys-surface-dim: rgb(20 19 19);
	--sys-surface-bright: rgb(58 57 56);
	--sys-surface-container-lowest: rgb(15 14 14);
	--sys-surface-container-low: rgb(28 27 27);
	--sys-surface-container: rgb(32 31 31);
	--sys-surface-container-high: rgb(43 42 41);
	--sys-surface-container-highest: rgb(54 52 52);
	--success-color: rgb(85 253 60);
	--success-on-color: rgb(3 57 0);
	--success-color-container: rgb(25 208 0);
	--success-on-color-container: rgb(2 48 0);
	--partial-color: rgb(255 195 141);
	--partial-on-color: rgb(75 40 0);
	--partial-color-container: rgb(242 141 0);
	--partial-on-color-container: rgb(45 22 0);
	--info-color-color: rgb(136 209 237);
	--info-color-on-color: rgb(0 53 68);
	--info-color-color-container: rgb(0 90 113);
	--info-color-on-color-container: rgb(232 247 255);
}
