// This file was generated by running 'ng generate @angular/material:m3-theme'.
// Proceed with caution if making changes to this file.

@use 'sass:map';
@use '@angular/material' as mat;

@include mat.core();

// Note: Color palettes are generated from primary: #392121, secondary: #63152c, tertiary: #8b5000, neutral: #fdf8f7
$_palettes: (
	primary: (
		0: #000000,
		10: #410009,
		20: #5f131a,
		25: #6e1e24,
		30: #7e2a2e,
		35: #8d3539,
		40: #9c4144,
		50: #bb595b,
		60: #db7173,
		70: #fb8b8c,
		80: #ffb3b2,
		90: #ffdad9,
		95: #ffedec,
		98: #fff8f7,
		99: #fffbff,
		100: #ffffff,
	),
	secondary: (
		0: #000000,
		10: #3f0015,
		20: #5f1129,
		25: #6e1d34,
		30: #7c293f,
		35: #8b344a,
		40: #9b4056,
		50: #b9586e,
		60: #d87187,
		70: #f88aa1,
		80: #ffb2bf,
		90: #ffd9de,
		95: #ffecee,
		98: #fff8f7,
		99: #fffbff,
		100: #ffffff,
	),
	tertiary: (
		0: #000000,
		10: #2d1600,
		20: #4a2800,
		25: #5a3200,
		30: #6a3c00,
		35: #7a4600,
		40: #8b5000,
		50: #ad6607,
		60: #cc7f26,
		70: #eb993f,
		80: #ffb871,
		90: #ffdcbe,
		95: #ffeee1,
		98: #fff8f5,
		99: #fffbff,
		100: #ffffff,
	),
	neutral: (
		0: #000000,
		4: #170400,
		6: #230600,
		10: #3a0a00,
		12: #410d00,
		17: #531301,
		20: #5e1701,
		22: #641b04,
		24: #6a2007,
		25: #6d2209,
		30: #7c2d14,
		35: #8b381e,
		40: #9b4429,
		50: #ba5c3e,
		60: #da7455,
		70: #fa8e6d,
		80: #ffb59f,
		87: #ffd0c2,
		90: #ffdbd1,
		92: #ffe2da,
		94: #ffe9e3,
		95: #ffede8,
		96: #fff1ed,
		98: #fff8f6,
		99: #fffbff,
		100: #ffffff,
	),
	neutral-variant: (
		0: #000000,
		10: #251818,
		20: #3b2d2d,
		25: #463838,
		30: #524343,
		35: #5f4f4e,
		40: #6b5a5a,
		50: #857372,
		60: #a08c8c,
		70: #bba6a6,
		80: #d7c1c1,
		90: #f4dddc,
		95: #ffedec,
		98: #fff8f7,
		99: #fffbff,
		100: #ffffff,
	),
	error: (
		0: #000000,
		10: #410002,
		20: #690005,
		25: #7e0007,
		30: #93000a,
		35: #a80710,
		40: #ba1a1a,
		50: #de3730,
		60: #ff5449,
		70: #ff897d,
		80: #ffb4ab,
		90: #ffdad6,
		95: #ffedea,
		98: #fff8f7,
		99: #fffbff,
		100: #ffffff,
	),
);

$_rest: (
	secondary: map.get($_palettes, secondary),
	neutral: map.get($_palettes, neutral),
	neutral-variant: map.get($_palettes, neutral-variant),
	error: map.get($_palettes, error),
);
$_primary: map.merge(map.get($_palettes, primary), $_rest);
$_tertiary: map.merge(map.get($_palettes, tertiary), $_rest);

$light-theme: mat.define-theme(
	(
		color: (
			theme-type: light,
			primary: $_primary,
			tertiary: $_tertiary,
			use-system-variables: true,
		),
		typography: (
			use-system-variables: true,
		),
	)
);

$dark-theme: mat.define-theme(
	(
		color: (
			theme-type: dark,
			primary: $_primary,
			tertiary: $_tertiary,
			use-system-variables: true,
		),
		typography: (
			use-system-variables: true,
		),
	)
);

:root {
	// Apply the light theme by default
	@include mat.core-theme($light-theme);
	@include mat.button-theme($light-theme);
	@include mat.toolbar-theme($light-theme);
	@include mat.icon-theme($light-theme);
	@include mat.menu-theme($light-theme);
	// @include mat.system-level-colors($light-theme);
	// @include mat.system-level-typography($light-theme);
}
.dark-theme {
	@include mat.core-color($dark-theme);
	@include mat.button-color($dark-theme);
	@include mat.toolbar-color($dark-theme);
	@include mat.icon-color($dark-theme);
	@include mat.menu-color($dark-theme);
}
