@use './app_variables';
@import './app_reset';

@media print {
	.no-print,
	.no-print * {
		display: none !important;
	}
}
.app-main {
	background-color: var(--sys-surface);
	min-height: calc(100vh - 2 * var(--mat-toolbar-standard-height));
}

.button-unstyled {
	border: none;
	color: unset;
	padding: 0px;
	margin: 0px;
	background-color: transparent;
	font-weight: inherit;
}
